import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import classNames from "classnames"
import styles from "./article-view.module.scss"
import { GlobalPromotionCTA } from "@features/global-promotions/components/global-promotion-cta"
import { SidebarTrack } from "@components/sidebar-track/sidebar-track"
import { Markdown } from "@components/markdown/markdown"
import { useGlobalPromotion } from "@features/global-promotions/hooks/use-global-promotion"

export interface ArticleViewProps {
  body: string
  timeToRead: number | null | undefined
  isTest?: boolean
}

export const ArticleView: FunctionComponent<ArticleViewProps> = ({
  body,
  timeToRead,
  isTest
}) => {
  const hasGlobalPromotion = Boolean(useGlobalPromotion())

  const shouldShowGlobalPromo = !isTest && hasGlobalPromotion

  return (
    <section className={styles.articleView}>
      <div className={classNames(styles.content)}>
        <span className={styles.timeToRead}>
          <FormattedMessage
            id="general-reading-time"
            values={{ time: timeToRead }}
          />
        </span>
        <Markdown className={styles.body} content={body} />
      </div>
      {shouldShowGlobalPromo && (
        <SidebarTrack>
          <GlobalPromotionCTA />
        </SidebarTrack>
      )}
    </section>
  )
}
