import React, { FunctionComponent } from "react"
import { Attribution } from "@components/attribution/attribution"
import { ArticleDetails } from "@features/articles/article-types"

export interface ProductLinkAttributionProps {
  article: ArticleDetails
}

export const ProductLinkAttribution: FunctionComponent<
  ProductLinkAttributionProps
> = ({ article }) => {
  return (
    <>
      {article?.productLinkExternal && (
        <Attribution
          isExternal
          imageSrc={article?.productLinkExternal.image}
          buttonText="Buy Now"
          buttonLink={article?.productLinkExternal.url}
          body={`Previously published in *<a href="${article?.productLinkExternal.url}" target="_blank" rel="no-referrer">${article?.productLinkExternal.title}</a>* by ${article?.productLinkExternal.author}.`}
        />
      )}
    </>
  )
}
