import React, { FunctionComponent, Fragment } from "react"
import classNames from "classnames"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useIntl } from "react-intl"
import { DetailsPageHeader } from "../../../../components/details-page-header/details-page-header"
import { ArticleDetails } from "../../article-types"
import { ArticleView } from "../article-view/article-view"
import styles from "./article-details-page.module.scss"
import recomStyles from "@components/recommendations/recommendations.module.scss"
import { AttributionWrapper } from "@components/attribution/attribution"
import { TabletalkAttribution } from "@features/tabletalk-attribution/tabletalk-attribution"
import { ProductLinkAttribution } from "@features/product-link-attribution/product-link-attribution"
import { GlobalPromotionCTA } from "@features/global-promotions/components/global-promotion-cta"
import { ArticleType, PathPrefix } from "@core/constants"
import { useGlobalPromotion } from "@features/global-promotions/hooks/use-global-promotion"
import { Recommendations } from "@components/recommendations/recommendations"
import {
  RelatedProductGroups,
  RelatedProducts
} from "@components/related-products/related-products"

export interface ArticleDetailsPageProps {
  article: ArticleDetails
  relatedProducts?: RelatedProductGroups | null
  isTest?: boolean
}

export const ArticleDetailsPage: FunctionComponent<ArticleDetailsPageProps> = ({
  article,
  relatedProducts,
  isTest
}) => {
  const { formatMessage } = useIntl()

  const showTabletalkAttribution =
    article.type === ArticleType.Tabletalk || article.showTabletalkAttribution

  const hasGlobalPromotion = Boolean(useGlobalPromotion())

  const shouldShowGlobalPromo = !isTest && hasGlobalPromotion

  const globalPromo = shouldShowGlobalPromo ? (
    <GlobalPromotionCTA className={styles.globalPromoMobile} />
  ) : null

  const { teachers, primaryTopic } = article
  const teacher = teachers[0]
  const articleImage = article.image && getImage(article.image)

  return (
    <Fragment>
      <DetailsPageHeader
        topics={article.topics}
        title={article.title}
        date={article.date}
        teachers={article.teachers}
        share
        iconVariant="12-article"
        id={article.contentfulId}
        contentType="article"
      >
        {articleImage && (
          <div className={styles.image}>
            <GatsbyImage image={articleImage} alt="" loading="eager" />
          </div>
        )}
      </DetailsPageHeader>
      <ArticleView
        body={article.extendedCopy || article.body || ""}
        timeToRead={article.timeToRead}
        isTest={isTest}
      />
      {article.productLinkExternal ? (
        <ProductLinkAttribution article={article}>
          {globalPromo}
        </ProductLinkAttribution>
      ) : showTabletalkAttribution ? (
        <TabletalkAttribution className={styles.attributions}>
          {globalPromo}
        </TabletalkAttribution>
      ) : shouldShowGlobalPromo ? (
        <AttributionWrapper
          className={classNames(styles.attributions, styles.globalPromoOnly)}
        >
          {globalPromo}
        </AttributionWrapper>
      ) : null}
      {relatedProducts && (
        <RelatedProducts
          products={relatedProducts}
          renderLabel={(label) =>
            formatMessage({ id: `store-product-productType-${label}` })
          }
        />
      )}
      <div className={recomStyles.relatedResourcesWrapper}>
        {teacher?.slug && (
          <Recommendations
            recombeeConfig={{
              type: "teacher",
              slug: teacher.slug,
              idsToRemove: [article.contentfulId],
              itemId: article.contentfulId
            }}
            title={teacher.name || ""}
            overline="More from this teacher"
            body={teacher.shortBio}
            link={`${PathPrefix.Teachers}/${teacher.slug}`}
          />
        )}
        {primaryTopic?.slug && (
          <Recommendations
            recombeeConfig={{
              type: "topic",
              slug: primaryTopic.slug,
              idsToRemove: [article.contentfulId],
              itemId: article.contentfulId
            }}
            title={primaryTopic.name || ""}
            overline="More on this topic"
            body={primaryTopic.description?.description}
            link={`${PathPrefix.Topics}/${primaryTopic.slug}`}
          />
        )}
      </div>
    </Fragment>
  )
}
